import { createContext, useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { logError, logInfo } from '../shared/logger'
import config from '../shared/config'
import { Crisp } from 'crisp-sdk-web'
import { useEffectOnce } from '../shared/hooks'
import { useUserQuery } from '../shared/queryHooks'
import posthog from 'posthog-js'
import { useSearchParams } from 'react-router-dom'

export const LAST_ORGANIZATION_ID_KEY = 'lastOrganizationId'
export const INVITE_CODE_KEY = 'inviteCode'

export const AuthContext = createContext({
  signOutUser: () => Promise.resolve(),
  isLoading: true,
  currentUser: null,
  isInitialLoading: true,
  isSigningOut: false,
  refreshUser: () => Promise.resolve()
})

const AuthProvider = (props) => {
  const [searchParams] = useSearchParams()
  const organizationIdOverride = searchParams.get('organization')

  const [isLoading, setIsLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState(null)
  const [isSigningOut, setIsSigningOut] = useState(false)

  function setOrganizationId (organizationId) {
    localStorage.setItem(LAST_ORGANIZATION_ID_KEY, organizationId.toString())
  }

  const { isInitialLoading, isError, data, error, refetch } = useUserQuery()

  if (organizationIdOverride) {
    setOrganizationId(organizationIdOverride)
  }

  if (isError) {
    logError(error)
  }

  const refreshUser = async () => {
    await refetch()
  }

  if (data && currentUser && data !== currentUser) {
    setCurrentUser(data)
  }

  if (data && !currentUser) {
    try {
      if (!organizationIdOverride) {
        setOrganizationId(data?.currentOrganizationId)
      }
      const fullName = `${data.firstName ?? ''} ${data.lastName ?? ''}`
      Crisp.user.setEmail(data.email)
      Crisp.user.setNickname(fullName)
      Sentry.setUser({
        authUid: data?.authUid,
        email: data?.email,
        name: fullName
      })
      posthog.identify(data.authUid, { email: data?.email, name: fullName })

      setCurrentUser(data)
    } catch (error) {
      logError(error)
    } finally {
      setIsLoading(false)
    }
  }

  function signOutUser () {
    setIsSigningOut(true)
    logInfo('Signing out user')
    posthog.reset()
    Sentry.setUser(null)
    setCurrentUser(null)
    window.location.replace(`${config.API_HOST}/auth/logout`)
    logInfo('signOutUser')
  }

  return (
    <AuthContext.Provider
      value={{
        signOutUser,
        isLoading,
        currentUser,
        isInitialLoading,
        isSigningOut,
        refreshUser
      }}
      {...props}
    />
  )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
